const Banner3 = () => {
  return (
    <section className="banner-area-three">
      <div
        className="banner-shape"
        data-background="assets/img/banner/h3_banner_shape.png"
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-10">
            <div className="banner-content-three text-center">
              <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                Technology Transform Your <span>Words into Voice</span>
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".4s">
                Artificial intelligence makes it fast & easy to create content
                for your blog, social media, website, and more! Rated 5/5 stars
                in 3,000+ reviews.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner3;
