// export const MAIN_SCREEN = '/';
export const LOGIN_SCREEN = '/login';
export const REGISTER_SCREEN = '/register';
export const ADMIN_SCREEN = '/admin';
export const FORGOT_PASSWORD_SCREEN = '/forgot-password';

export const allScreens = [
  // MAIN_SCREEN,
  LOGIN_SCREEN,
  REGISTER_SCREEN,
  ADMIN_SCREEN,
  FORGOT_PASSWORD_SCREEN,
];
