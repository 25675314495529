import { Subject, from, take } from 'rxjs';
import { GET } from '../api/fetch-api';

class PredictService {
  initialState = {
    loading: false,
    error: null,
    predict: '',
  };

  state = this.initialState;
  state$ = new Subject();

  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
  }

  async getData(currency) {
    if (this.state.loading) {
      return;
    }

    this.state = {
      ...this.state,
      loading: true,
      error: null,
    };
    this.state$.next(this.state);

    const data$ = from(GET(`${this.apiUrl}/admin/predict`)).pipe(take(1));

    data$.subscribe({
      next: (result) => {
        this.state = {
          ...this.state,
          error: null,
          loading: false,
          predict: result.data.prediction.prediction,
        };

        this.state$.next(this.state);
      },
      error: (error) => {
        this.state = {
          ...this.initialState,
          error: error.message,
        };
        this.state$.next(this.state);
      },
    });
  }
}

const PredictServiceInstance = new PredictService();
export default PredictServiceInstance;
