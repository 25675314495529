import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { Collapse } from 'react-collapse';

function MenuMobile({ handleCloseMenu }) {
  // active menu current
  const { pathname } = useLocation();
  const [current, setCurrent] = useState('home1');

  const [toggle, setToggle] = useState({
    key: '',
    status: false,
  });

  useEffect(() => {
    if (pathname) {
      let activePath = pathname.slice(1, pathname.length);
      setCurrent(activePath ? activePath : 'home1');
    }
  }, [pathname]);

  const handleCurrent = useCallback(
    (param = '') => {
      setCurrent(param);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current]
  );

  const checkActive = (param) => {
    if (param === 'pages') {
      switch (current) {
        case 'pages':
        case 'work':
        case 'faq': {
          return true;
        }
        default:
          return false;
      }
    } else if (param === 'home') {
      switch (current) {
        case 'home':
        case 'home1':
        case 'index-2':
        case 'index-3': {
          return true;
        }
        default:
          return false;
      }
    } else {
      switch (current) {
        case 'news':
        case 'blog':
        case 'blog-details': {
          return true;
        }
        default:
          return false;
      }
    }
  };

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
    // setCurrent(key);
  };

  return (
    <ul className="navigation">
      <li
        className={`${
          current === 'work' ? 'active' : ''
        } menu-item-has-children tg-mega-menu-has-children`}
        onClick={handleCloseMenu}
      >
        <Link to="/work">How it work</Link>
      </li>
      <li
        className={`${
          current === 'faq' ? 'active' : ''
        } menu-item-has-children tg-mega-menu-has-children`}
        onClick={handleCloseMenu}
      >
        <Link to="/faq">FAQ page</Link>
      </li>

      <li
        className={`${
          current === 'contact' ? 'active' : ''
        } menu-item-has-children tg-mega-menu-has-children`}
        onClick={handleCloseMenu}
      >
        <Link to="/contact">contacts</Link>
      </li>
    </ul>
  );
}

export default MenuMobile;
