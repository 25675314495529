import useContent from 'hooks/useContent';
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';
import { CONTENT_ID } from 'utils/content';
import CallToActions from '../../../components/layout/callToActions/CallToActions';

const Banner1 = () => {
  const { content } = useContent();

  const scrollToAnchor = (anchorId) => {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="banner-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="banner-content ta-animated-headline">
              <h2
                className="title ah-headline wow fadeInUp"
                data-wow-delay=".2s"
              >
                Discover the
                <span> Power</span> of data-driven insights
              </h2>

              <h2 className="title d-none wow fadeInUp" data-wow-delay=".2s">
                Whatever You want to ask- 0xCoin has the <span>Answers,</span>{' '}
                <span>Solutions</span>
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".4s">
                AI that analyses data from social media, news, whale movements,
                and blockchain transactions to provide you with actionable
                insights.
              </p>
              <CallToActions />

              {/* <div className="banner-btn" style={{ marginTop: '60px' }}>
                <div
                  className="gradient-btn wow fadeInLeft"
                  onClick={() => scrollToAnchor('subscribe')}
                >
                  Sign Up Now
                </div>
                <div
                  onClick={() => scrollToAnchor('subscribe')}
                  className="gradient-btn gradient-btn-two wow fadeInRight"
                >
                  Start Your Free Trial
                </div> */}
              {/* <Link
                  to="/login"
                  
                  data-wow-delay=".6s"
                >
                </Link> */}
              {/* <Link to="/work" data-wow-delay=".6s"></Link> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner1;
