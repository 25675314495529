export const messages = [
    {
        id: 1,
        source: '../../assets/2.2/App/demo/img/profile-pics/1.jpg',
        text: 'David Belle',
        smallText: '12:01 PM',
        paragraph: 'Cum sociis natoque penatibus et magnis dis parturient montes',
    },

    {
        id: 2,
        source: '../../assets/2.2/App/demo/img/profile-pics/2.jpg',
        text: '  Jonathan Morris',
        smallText: '02:45 PM',
        paragraph: 'Nunc quis diam diamurabitur at dolor elementum, dictum turpis vel',
    },

    {
        id: 3,
        source: '../../assets/2.2/App/demo/img/profile-pics/3.jpg',
        text: 'Fredric Mitchell Jr.',
        smallText: '08:21 PM',
        paragraph: 'Phasellus a ante et est ornare accumsan at vel magnauis blandit turpis at augue ultricies',
    },

    
    {
        id: 4,
        source: '../../assets/2.2/App/demo/img/profile-pics/4.jpg',
        text: 'Glenn Jecobs',
        smallText: '08:43 PM PM',
        paragraph: 'Ut vitae lacus sem ellentesque maximus, nunc sit amet varius dignissim, dui est consectetur neque',
    },


    
    {
        id: 5,
        source: '../../assets/2.2/App/demo/img/profile-pics/5.jpg',
        text: 'Bill Phillips',
        smallText: '11:32 PM',
        paragraph: 'Proin laoreet commodo eros id faucibus. Donec ligula quam, imperdiet vel ante placerat',
    },
];

export const notifications = [
    {
        id: 1,
        source: '../../assets/2.2/App/demo/img/profile-pics/1.jpg',
        text: 'David Belle',
        paragraph: 'Cum sociis natoque penatibus et magnis dis parturient montes',
    },

    {
        id: 2,
        source: '../../assets/2.2/App/demo/img/profile-pics/2.jpg',
        text: 'Jonathan Morris',
        paragraph: 'Nunc quis diam diamurabitur at dolor elementum, dictum turpis vel',
    },

    {
        id: 3,
        source: '../../assets/2.2/App/demo/img/profile-pics/3.jpg',
        text: 'Jonathan Morris',
        paragraph: 'Nunc quis diam diamurabitur at dolor elementum, dictum turpis vel',
    },

    {
        id: 4,
        source: '../../assets/2.2/App/demo/img/profile-pics/4.jpg',
        text: 'Jonathan Morris',
        paragraph: 'Nunc quis diam diamurabitur at dolor elementum, dictum turpis vel',
    },

    {
        id: 5,
        source: '../../assets/2.2/App/demo/img/profile-pics/5.jpg',
        text: 'Jonathan Morris',
        paragraph: 'Nunc quis diam diamurabitur at dolor elementum, dictum turpis vel',
    },
];

export const news = [
    
]