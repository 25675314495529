import { useEffect, useState } from 'react';
import ChartPreview from './chartPreview/QuickStat';
import { chartPreviewsAttributes } from './chartPreviewsAttributes.data';
import PredictServiceInstance from 'services/predict.service';
import usePredict from 'hooks/usePredict';

const ChartPreviews = () => {
  const { predict } = usePredict();
  const [chartPreviews, setChartPreviews] = useState(chartPreviewsAttributes);
  useEffect(() => {
    PredictServiceInstance.getData();
  }, []);
  useEffect(() => {
    if (predict) {
      setChartPreviews((prev) => [{ ...prev[0], subTitle: predict }]);
    }
  }, [predict]);
  return (
    <>
      {chartPreviews.map((chartPreview) => (
        <ChartPreview key={chartPreview.id} chartPreview={chartPreview} />
      ))}
    </>
  );
};

export default ChartPreviews;
