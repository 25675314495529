const NewsPreview = ({ newsPreview }) => {
  return (
    <li className="admin-card">
      <div className="admin-card-body">
        <div className="admin-card-title" style={{ display: 'flex', gap: '10px', alignItems: 'flex-end' }}>
          <h2 style={{ margin: 0, lineHeight: '1.55rem' }}>{newsPreview.title}</h2>
        </div>
        <div
          style={{
            whiteSpace: 'pre-wrap',
            textOverflow: 'unset',
            lineHeight: '18px',
            style: '16px',
          }}
          dangerouslySetInnerHTML={{ __html: newsPreview.subTitle }}
        />
      </div>
    </li>
  );
};

export default NewsPreview;
