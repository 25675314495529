import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { CONTENT_ID } from 'utils/content';
import useContent from 'hooks/useContent';

const Pricing1 = () => {
  const [isToggled, setToggled] = useState(false);
  const toggleTrueFalse = () => setToggled(!isToggled);
  const { content } = useContent();

  return (
    <section className="pricing-area pb-105">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb-50">
              <h2
                id="subscribe"
                className="title title-animation wow fadeInUp"
                data-wow-delay=".2s"
                // dangerouslySetInnerHTML={{
                //   __html:
                //     content[CONTENT_ID.HEADER_PRICING] ||
                //     'Money well <span>invested</span>',
                // }}
              >
                Pricing
              </h2>
            </div>
          </div>
        </div>
        <div className="pricing-item-wrap">
          <div className="pricing-billing-duration text-center">
            {/* <div className="pricing-tab" onClick={toggleTrueFalse}>
              <span
                className="tab-btn monthly_tab_title"
                dangerouslySetInnerHTML={{
                  __html: content[CONTENT_ID.PERIOD1_PRICING] || 'Monthly',
                }}
              />
              <span
                className={
                  isToggled
                    ? 'pricing-tab-switcher active'
                    : ' pricing-tab-switcher'
                }
              />
              <span
                className="tab-btn annual_tab_title"
                dangerouslySetInnerHTML={{
                  __html: content[CONTENT_ID.PERIOD2_PRICING] || 'Yearly',
                }}
              />
            </div> */}
          </div>
          <div className="row justify-content-center">
            {/* <div className="col-xl-4 col-lg-6 col-md-9 col-sm-10">
              <div className="pricing-item wow fadeInLeft" data-wow-delay=".2s">
                <div className="pricing-shape">
                  <svg
                    viewBox="0 0 410 616"
                    fill="none"
                    x="0px"
                    y="0px"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M85.6497 0.634114C102.343 1.89097 115.705 2.89697 134 22.4989C134.632 23.176 135.238 23.8305 135.823 24.4624C145.21 34.5995 149.203 38.9119 168.5 37.4993C179.699 36.6795 228.167 37.1659 251 37.4993H251.001C262.001 37.4993 270.501 37.4993 289 16C301.111 1.92454 315.232 0.174842 333.448 0H380C396.569 0 410 13.4315 410 30V586C410 602.569 396.569 616 380 616H30C13.4315 616 0 602.569 0 586V30C0 13.4315 13.4315 0 30 0H78.0075C80.6454 0.257338 83.1839 0.448462 85.6497 0.634114Z"
                      fill="currentcolor"
                    />
                  </svg>
                </div>
                <div className="pricing-top">
                  <div className="left-side">
                    <div className="icon">
                      <img src="/assets/img/icon/pricing_icon.svg" alt="" />
                    </div>
                    <div className="content" style={{ padding: '0' }}>
                      <h4 className="title">Basic</h4>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            content[CONTENT_ID.SERVICES1_PRICING] ||
                            '03 Services',
                        }}
                      />
                    </div>
                  </div>
                  <div className="pricing-price">
                    <h2 className="title monthly_price">Free</h2>
                    <h2 className="title annual_price">Free</h2>
                  </div>
                </div>
                <div class="pricing-list">
                  <ul class="list-wrap">
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES1_PRICING__LI1] ||
                          '<li>15 000 words/month</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES1_PRICING__LI2] ||
                          '<li>Write in 10 languages</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES1_PRICING__LI3] ||
                          '<li>Image generation (40/month)</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES1_PRICING__LI4] ||
                          '<li class="delete">25+ languages</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES1_PRICING__LI5] ||
                          '<li class="delete">Unlimited projects</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES1_PRICING__LI6] ||
                          '<li class="delete">Unlimited Marvel Chat</li>',
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES1_PRICING__LI7] ||
                          '<li class="delete">New experimental features</li>',
                      }}
                    />
                  </ul>
                </div>
                <div className="pricing-btn">
                  <Link to="/login" className="btn btn-two">
                    choose plan
                  </Link>
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-4 col-lg-6 col-md-9 col-sm-10">
              <div
                className="pricing-item active wow fadeInUp"
                data-wow-delay=".5s"
              >
                <span
                  className="popular"
                  dangerouslySetInnerHTML={{
                    __html:
                      content[CONTENT_ID.POPULAR_PRICING] || 'Most popular',
                  }}
                />
                <div className="pricing-shape">
                  <svg
                    viewBox="0 0 410 616"
                    fill="none"
                    x="0px"
                    y="0px"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M85.6497 0.634114C102.343 1.89097 115.705 2.89697 134 22.4989C134.632 23.176 135.238 23.8305 135.823 24.4624C145.21 34.5995 149.203 38.9119 168.5 37.4993C179.699 36.6795 228.167 37.1659 251 37.4993H251.001C262.001 37.4993 270.501 37.4993 289 16C301.111 1.92454 315.232 0.174842 333.448 0H380C396.569 0 410 13.4315 410 30V586C410 602.569 396.569 616 380 616H30C13.4315 616 0 602.569 0 586V30C0 13.4315 13.4315 0 30 0H78.0075C80.6454 0.257338 83.1839 0.448462 85.6497 0.634114Z"
                      fill="url(#pricing)"
                    />
                    <defs>
                      <linearGradient
                        id="pricing"
                        x1="-35.4999"
                        y1="-46.5001"
                        x2="393.384"
                        y2="749.254"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0.0246007" stopColor="#C8BDFF" />
                        <stop offset="0.0246007" stopColor="#BAA6FF" />
                        <stop offset="0.214137" stopColor="#6721FF" />
                        <stop offset="0.486687" stopColor="#6721FF" />
                        <stop offset={1} stopColor="#00CBFF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="pricing-top">
                  <div className="left-side">
                    <div className="icon">
                      <img src="/assets/img/icon/pricing_icon.svg" alt="" />
                    </div>
                    <div className="content" style={{ padding: '0' }}>
                      <h4 className="title">Standard</h4>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            content[CONTENT_ID.SERVICES2_PRICING] ||
                            '05 Services',
                        }}
                      />
                    </div>
                  </div>
                  <div className="pricing-price">
                    <h2 className="title monthly_price">
                      {/* ${isToggled ? '299' : '39'} 
                      $30
                    </h2>
                  </div>
                </div>
                <div className="pricing-list">
                  <ul className="list-wrap">
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES2_PRICING__LI1] ||
                          '<li>15 000 words/month</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES2_PRICING__LI2] ||
                          '<li>Write in 10 languages</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES2_PRICING__LI3] ||
                          '<li>Image generation (40/month)</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES2_PRICING__LI4] ||
                          '<li>25+ languages</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES2_PRICING__LI5] ||
                          '<li>Unlimited projects</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES2_PRICING__LI6] ||
                          '<li class="delete">Unlimited Marvel Chat</li>',
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES2_PRICING__LI7] ||
                          '<li class="delete">New experimental features</li>',
                      }}
                    />
                  </ul>
                </div>
                <div className="pricing-btn">
                  <Link to="/login" className="btn btn-two">
                    choose plan
                  </Link>
                </div>
              </div>
            </div> */}
            <div className="col-xl-4 col-lg-6 col-md-9 col-sm-10">
              <div
                className="pricing-item wow fadeInRight"
                data-wow-delay=".2s"
              >
                <div className="pricing-shape">
                  <svg
                    viewBox="0 0 410 616"
                    fill="none"
                    x="0px"
                    y="0px"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M85.6497 0.634114C102.343 1.89097 115.705 2.89697 134 22.4989C134.632 23.176 135.238 23.8305 135.823 24.4624C145.21 34.5995 149.203 38.9119 168.5 37.4993C179.699 36.6795 228.167 37.1659 251 37.4993H251.001C262.001 37.4993 270.501 37.4993 289 16C301.111 1.92454 315.232 0.174842 333.448 0H380C396.569 0 410 13.4315 410 30V586C410 602.569 396.569 616 380 616H30C13.4315 616 0 602.569 0 586V30C0 13.4315 13.4315 0 30 0H78.0075C80.6454 0.257338 83.1839 0.448462 85.6497 0.634114Z"
                      fill="currentcolor"
                    />
                  </svg>
                </div>
                <div className="pricing-top">
                  <div className="left-side">
                    <div className="icon">
                      <img src="/assets/img/icon/pricing_icon.svg" alt="" />
                    </div>
                    <div className="content" style={{ padding: '0' }}>
                      <h4 className="title">Monthly subscription</h4>
                      <span>1 week free trial</span>
                    </div>
                  </div>
                  <div className="pricing-price">
                    <h2 className="title monthly_price">$30</h2>
                  </div>
                </div>
                <div className="pricing-list">
                  {/* <ul className="list-wrap">
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI1] ||
                          '<li>15 000 words/month</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI2] ||
                          '<li>Write in 10 languages</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI3] ||
                          '<li>Image generation (40/month)</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI4] ||
                          '<li>25+ languages</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI5] ||
                          '<li>Unlimited projects</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI6] ||
                          '<li>Unlimited Marvel Chat</li>',
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI7] ||
                          '<li>New experimental features</li>',
                      }}
                    />
                  </ul> */}
                </div>
                <div className="pricing-btn">
                  <Link to="/login" className="btn btn-two">
                    choose plan
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-9 col-sm-10">
              <div
                className="pricing-item wow fadeInRight"
                data-wow-delay=".2s"
              >
                <div className="pricing-shape">
                  <svg
                    viewBox="0 0 410 616"
                    fill="none"
                    x="0px"
                    y="0px"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M85.6497 0.634114C102.343 1.89097 115.705 2.89697 134 22.4989C134.632 23.176 135.238 23.8305 135.823 24.4624C145.21 34.5995 149.203 38.9119 168.5 37.4993C179.699 36.6795 228.167 37.1659 251 37.4993H251.001C262.001 37.4993 270.501 37.4993 289 16C301.111 1.92454 315.232 0.174842 333.448 0H380C396.569 0 410 13.4315 410 30V586C410 602.569 396.569 616 380 616H30C13.4315 616 0 602.569 0 586V30C0 13.4315 13.4315 0 30 0H78.0075C80.6454 0.257338 83.1839 0.448462 85.6497 0.634114Z"
                      fill="currentcolor"
                    />
                  </svg>
                </div>
                <div className="pricing-top">
                  <div className="left-side">
                    <div className="icon">
                      <img src="/assets/img/icon/pricing_icon.svg" alt="" />
                    </div>
                    <div className="content" style={{ padding: '0' }}>
                      <h4 className="title">Yearly Subscription</h4>
                      <span>1 week free trial</span>
                      {/* <span>1 week free trial</span> */}
                    </div>
                  </div>
                  <div className="pricing-price">
                    <h2 className="title monthly_price">$300</h2>
                  </div>
                </div>
                <div className="pricing-list">
                  {/* <ul className="list-wrap">
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI1] ||
                          '<li>15 000 words/month</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI2] ||
                          '<li>Write in 10 languages</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI3] ||
                          '<li>Image generation (40/month)</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI4] ||
                          '<li>25+ languages</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI5] ||
                          '<li>Unlimited projects</li>',
                      }}
                    />
                    <div
                      style={{ marginBottom: '17px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI6] ||
                          '<li>Unlimited Marvel Chat</li>',
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          content[CONTENT_ID.SERVICES3_PRICING__LI7] ||
                          '<li>New experimental features</li>',
                      }}
                    />
                  </ul> */}
                </div>
                <div className="pricing-btn">
                  <Link to="/login" className="btn btn-two">
                    choose plan
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Pricing1;
