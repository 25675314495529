export const CONTENT_ID = {
  HEADER_BANNER: 'header_banner',

  HEADER_WRITING: 'header_writing',

  PRICING: 'pricing1',

  FREE_TRIAL_BUTTON: 'free_trial_button',

  CARD1_TITLE_WRITING: 'card1_title_writing',
  CARD1_CONTENT_WRITING: 'card1_content_writing',
  CARD1_FOOTER_WRITING: 'card2_footer_writing',
  CARD2_TITLE_WRITING: 'card2_title_writing',
  CARD2_CONTENT_WRITING: 'card2_content_writing',
  CARD2_FOOTER_WRITING: 'card2_footer_writing',
  CARD3_TITLE_WRITING: 'card3_title_writing',
  CARD3_CONTENT_WRITING: 'card3_content_writing',
  CARD3_FOOTER_WRITING: 'card3_footer_writing',
  CARD4_TITLE_WRITING: 'card4_title_writing',
  CARD4_CONTENT_WRITING: 'card4_content_writing',
  CARD4_FOOTER_WRITING: 'card4_footer_writing',
  CARD5_TITLE_WRITING: 'card5_title_writing',
  CARD5_CONTENT_WRITING: 'card5_content_writing',
  CARD5_FOOTER_WRITING: 'card5_footer_writing',
  CARD6_TITLE_WRITING: 'card6_title_writing',
  CARD6_CONTENT_WRITING: 'card6_content_writing',
  CARD6_FOOTER_WRITING: 'card6_footer_writing',

  HEADER_ROADMAP: 'header_roadmap',
  TITLE1_ROADMAP: 'title1_roadmap',
  CONTENT1_ROADMAP: 'content1_roadmap',
  TITLE2_ROADMAP: 'title2_roadmap',
  CONTENT2_ROADMAP: 'content2_roadmap',
  TITLE3_ROADMAP: 'title3_roadmap',
  CONTENT3_ROADMAP: 'content3_roadmap',

  HEADER_PRICING: 'header_pricing',
  PERIOD1_PRICING: 'period1_pricing',
  PERIOD2_PRICING: 'period2_pricing',
  POPULAR_PRICING: 'popular_pricing',
  SERVICES1_PRICING: 'services1_pricing',
  SERVICES2_PRICING: 'services2_pricing',
  SERVICES3_PRICING: 'services3_pricing',
  SERVICES1_PRICING__LI1: 'services1_pricing_LI1',
  SERVICES1_PRICING__LI2: 'services1_pricing_LI2',
  SERVICES1_PRICING__LI3: 'services1_pricing_LI3',
  SERVICES1_PRICING__LI4: 'services1_pricing_LI4',
  SERVICES1_PRICING__LI5: 'services1_pricing_LI5',
  SERVICES1_PRICING__LI6: 'services1_pricing_LI6',
  SERVICES1_PRICING__LI7: 'services1_pricing_LI7',
  SERVICES2_PRICING__LI1: 'services2_pricing_LI1',
  SERVICES2_PRICING__LI2: 'services2_pricing_LI2',
  SERVICES2_PRICING__LI3: 'services2_pricing_LI3',
  SERVICES2_PRICING__LI4: 'services2_pricing_LI4',
  SERVICES2_PRICING__LI5: 'services2_pricing_LI5',
  SERVICES2_PRICING__LI6: 'services2_pricing_LI6',
  SERVICES2_PRICING__LI7: 'services2_pricing_LI7',
  SERVICES3_PRICING__LI1: 'services3_pricing_LI1',
  SERVICES3_PRICING__LI2: 'services3_pricing_LI2',
  SERVICES3_PRICING__LI3: 'services3_pricing_LI3',
  SERVICES3_PRICING__LI4: 'services3_pricing_LI4',
  SERVICES3_PRICING__LI5: 'services3_pricing_LI5',
  SERVICES3_PRICING__LI6: 'services3_pricing_LI6',
  SERVICES3_PRICING__LI7: 'services3_pricing_LI7',

  HEADER_TOOLS: 'header_tools',
  CARD1_LINK1_TOOLS: 'card1_link1_tools',
  CARD1_LINK2_TOOLS: 'card1_link2_tools',
  CARD2_LINK1_TOOLS: 'card2_link1_tools',
  CARD2_LINK2_TOOLS: 'card2_link2_tools',
  CARD3_LINK1_TOOLS: 'card3_link1_tools',
  CARD3_LINK2_TOOLS: 'card3_link2_tools',
  CARD4_LINK1_TOOLS: 'card4_link1_tools',
  CARD4_LINK2_TOOLS: 'card4_link2_tools',
  CARD5_LINK1_TOOLS: 'card5_link1_tools',
  CARD5_LINK2_TOOLS: 'card5_link2_tools',

  HEADER_WORK: 'header_work',
  STEP1_WORK: 'step1_work',
  STEP2_WORK: 'step2_work',
  STEP3_WORK: 'step3_work',
  STEP4_WORK: 'step4_work',
  TITLE1_WORK: 'title1_work',
  TITLE2_WORK: 'title2_work',
  TITLE3_WORK: 'title3_work',
  TITLE4_WORK: 'title4_work',
  TEXT1_WORK: 'text1_work',
  TEXT2_WORK: 'text2_work',
  TEXT3_WORK: 'text3_work',
  TEXT4_WORK: 'text4_work',

  HEADER_FAQ: 'header_faq',
  ACCORDION1_HEADER_FAQ: 'accordion1_header_faq',
  ACCORDION1_CONTENT_FAQ: 'accordion1_content_faq',
  ACCORDION2_HEADER_FAQ: 'accordion2_header_faq',
  ACCORDION2_CONTENT_FAQ: 'accordion2_content_faq',
  ACCORDION3_HEADER_FAQ: 'accordion3_header_faq',
  ACCORDION3_CONTENT_FAQ: 'accordion3_content_faq',
  ACCORDION4_HEADER_FAQ: 'accordion4_header_faq',
  ACCORDION4_CONTENT_FAQ: 'accordion4_content_faq',
  ACCORDION5_HEADER_FAQ: 'accordion5_header_faq',
  ACCORDION5_CONTENT_FAQ: 'accordion5_content_faq',
  ACCORDION6_HEADER_FAQ: 'accordion6_header_faq',
  ACCORDION6_CONTENT_FAQ: 'accordion6_content_faq',
  ACCORDION7_HEADER_FAQ: 'accordion7_header_faq',
  ACCORDION7_CONTENT_FAQ: 'accordion7_content_faq',

  HEADER_CONTACT: 'header_contact',
  CARD1_TITLE_CONTACT: 'card1_title_contact',
  CARD1_TEXT_CONTACT: 'card1_text_contact',
  CARD2_TITLE_CONTACT: 'card2_title_contact',
  CARD2_TEXT_CONTACT: 'card2_text_contact',
  CARD3_TITLE_CONTACT: 'card3_title_contact',
  CARD3_TEXT_CONTACT: 'card3_text_contact',
  FORM_HEADER_CONTANCT: 'form_header_contact',
  FORM_CONTACTS_CONTANCT: 'form_contacts_contact',

  HEADER_CONTENT_ABOUT: 'header_content_about',
  TITLE_CONTENT_ABOUT: 'title_content_about',
  TEXT_CONTENT_ABOUT: 'text_content_about',
  LI_CONTENT_ABOUT: 'li_content_about',
  TITLE_MEMBER_ABOUT: 'title_member_about',
  MEMBER1_TITLE_ABOUT: 'member1_title_about',
  MEMBER1_TEXT_ABOUT: 'member1_text_about',
  MEMBER2_TITLE_ABOUT: 'member2_title_about',
  MEMBER2_TEXT_ABOUT: 'member2_text_about',
  MEMBER1_NAME_ABOUT: 'member1_name_about',
  MEMBER1_JOBS_ABOUT: 'member1_jobs_about',
  MEMBER2_NAME_ABOUT: 'member2_name_about',
  MEMBER2_JOBS_ABOUT: 'member2_jobs_about',
  MEMBER3_NAME_ABOUT: 'member3_name_about',
  MEMBER3_JOBS_ABOUT: 'member3_jobs_about',
  MEMBER4_NAME_ABOUT: 'member4_name_about',
  MEMBER4_JOBS_ABOUT: 'member4_jobs_about',
  MEMBER5_NAME_ABOUT: 'member5_name_about',
  MEMBER5_JOBS_ABOUT: 'member5_jobs_about',
  TITLE_FOOTER_ABOUT: 'title_footer_about',
  TEXT_FOOTER_ABOUT: 'text_footer_about',
};
