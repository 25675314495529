import Layout from '../components/layout/Layout';
import { CONTENT_ID } from 'utils/content';
import useContent from 'hooks/useContent';
import AiImage from '../assets/img/images/analytics.jpg';
import CryptoImage from '../assets/img/images/crypto-novice.jpg';

const Work = () => {
  const { content } = useContent();

  return (
    <section className="work-area " style={{ marginTop: '60px' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb-70">
              <h2
                className="title title-animation wow fadeInUp"
                data-wow-delay=".2s"
              >
                How crypto <span>really works</span>
              </h2>
            </div>
            <div
              className="work-item"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="work-thumb">
                <img src={AiImage} alt="" />
              </div>
              <div className="work-content">
                <h2 className="title">
                  We prove time and time again that technical analysis is
                  ineffective in the crypto market. Here is why
                </h2>
                <ol>
                  <li
                    style={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <p>a.</p>
                    <p>
                      Manipulative practices targeting both enthusiasts and
                      trading bots
                    </p>
                  </li>
                  <li
                    style={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <p>b.</p>
                    <p> Prices are driven by Whales not indicators</p>
                  </li>
                  <li
                    style={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <p>c.</p>
                    <p>
                      Market dynamics, controlled by whales amplified by media
                      PR
                    </p>
                  </li>
                </ol>

                <p
                // dangerouslySetInnerHTML={{
                //   __html:
                //     content[CONTENT_ID.TEXT1_WORK] ||
                //     '0xCoin is an advanced AI system that leverages cutting-edge technologies and the latest Google data to provide accurate responses to every request. With its vast knowledge base and powerful algorithms,',
                // }}
                >
                  As a result you'll likely lose money trusting bots based only
                  on the indicators.
                </p>
              </div>
            </div>
            <div
              className="work-item"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="work-thumb">
                <img src={CryptoImage} alt="" />
              </div>
              <div className="work-content">
                <h2
                  className="title"
                  // dangerouslySetInnerHTML={{
                  //   __html:
                  //     content[CONTENT_ID.TITLE2_WORK] ||
                  //     'How AI Became Our Greatest Co-Worker',
                  // }}
                >
                  Other challenges faced by crypto novices
                </h2>
                <ol>
                  <li
                    style={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <p>a.</p>
                    <p>
                      <p>
                        <div style={{ fontWeight: 'bold', display: 'inline' }}>
                          Impact of Media.
                        </div>
                        Media PR and news are utilised alongside whale
                        manipulation to influence market trends.
                      </p>
                    </p>
                  </li>
                  <li
                    style={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <p>b.</p>
                    <p>
                      <div style={{ fontWeight: 'bold', display: 'inline' }}>
                        Lack of Access to Insider Insights.
                      </div>
                      Exchanges and industry experts have access to valuable
                      insights that are not available to the public.
                    </p>
                  </li>
                  <li
                    style={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <p>c.</p>
                    <p>
                      <div style={{ fontWeight: 'bold', display: 'inline' }}>
                        Time.
                      </div>
                      Vast amount of news and whale movements to analyse.
                    </p>
                  </li>
                </ol>
                {/* <p
                  dangerouslySetInnerHTML={{
                    __html:
                      content[CONTENT_ID.TEXT2_WORK] ||
                      'Are you struggling to stay on top of all your business tasks and responsibilities? Do you find yourself bogged down by administrative work and unable to focus on your core business activities? If so,',
                  }}
                /> */}
              </div>
            </div>
            {/* <div
              className="work-item"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="work-thumb">
                <img src="assets/img/images/work_img03.jpg" alt="" />
              </div>
              <div className="work-content">
                <span
                  dangerouslySetInnerHTML={{
                    __html: content[CONTENT_ID.STEP3_WORK] || 'Step 3',
                  }}
                />
                <h2
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html:
                      content[CONTENT_ID.TITLE3_WORK] ||
                      'Inspiration to Creation the Creative Art Maker',
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      content[CONTENT_ID.TEXT3_WORK] ||
                      "In today's digital age, creating visually appealing images is more important than ever. From social media posts to marketing materials, the right images can make all the difference in capturing the attention",
                  }}
                />
              </div>
            </div>
            <div
              className="work-item"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="work-thumb">
                <img src="assets/img/images/work_img04.jpg" alt="" />
              </div>
              <div className="work-content">
                <span
                  dangerouslySetInnerHTML={{
                    __html: content[CONTENT_ID.STEP4_WORK] || 'Step 4',
                  }}
                />
                <h2
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html:
                      content[CONTENT_ID.TITLE4_WORK] ||
                      'Prompt Library - AI for Community',
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      content[CONTENT_ID.TEXT4_WORK] ||
                      'Artificial intelligence is a powerful tool that can help businesses of all sizes solve complex problems, automate routine tasks, and gain valuable insights into their operations. However, the reality is that not many',
                  }}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Work;
