import { useEffect, useState } from 'react';
import NewsPreview from './newsPreview/NewsPreview';
import { newsPreviewsAttributes } from './newsPreviewsAttributes.data';
import NewsPredictServiceInstance from 'services/newsPredict.service';
import useNewsPredict from 'hooks/useNewsPredict';

const NewsPrediction = () => {
  const { predict } = useNewsPredict();
  const [newsPreviews, setNewsPreviews] = useState(newsPreviewsAttributes);
  useEffect(() => {
    NewsPredictServiceInstance.getData();
  }, []);
  useEffect(() => {
    console.log(predict);
    if (predict) {
      setNewsPreviews((prev) => [{ ...prev[0], subTitle: predict }]);
    }
  }, [predict]);
  return (
    <>
      {newsPreviews.map((newsPreview) => (
        <NewsPreview key={newsPreview.id} newsPreview={newsPreview} />
      ))}
    </>
  );
};

export default NewsPrediction;
