import Layout from '../components/layout/Layout';
import Banner1 from '../components/layout/banner/Banner1';
// import Counter1 from '../components/layout/counter/Counter1';
import Pricing1 from '../components/layout/pricing/Pricing1';
import Roadmap from '../components/layout/roadmap/Roadmap';
// import Testimonial1 from '../components/layout/testimonial/Testimonial1';
import Tools from '../components/layout/tools/Tools';
// import UseCases from '../components/layout/usecases/UseCases';
import Video from '../components/video/Video';
import Writing from '../components/layout/writing/Writing';
import CallToActions from '../components/layout/callToActions/CallToActions';
import Work from './Work';

const HomeOne = () => {
  return (
    <Layout mainCls="main-content fix" headerStyle={1} footerStyle={1}>
      <Banner1 />
      <Video />
      <Work />
      {/* <Counter1 /> */}
      <Writing />
      {/* <UseCases /> */}
      {/* <Roadmap /> */}

      <Pricing1 />

      <div className="section-title text-center mb-150">
        <h2
          id="subscribe"
          className="title title-animation wow fadeInUp"
          data-wow-delay=".2s"
          style={{ marginBottom: '16px' }}
        >
          Get Started <span>Today</span>
        </h2>
        <p
          className="wow fadeInUp"
          data-wow-delay=".4s"
          style={{ marginBottom: '80px' }}
        >
          Sign up now to access exclusive insights and take your crypto trading
          to the next level with 0xCoin!
        </p>
        <CallToActions />
      </div>

      <Tools />
      {/* <Testimonial1 /> */}
    </Layout>
  );
};

export default HomeOne;
