import React from 'react';
import { useNavigate } from 'react-router-dom';

const CalltoActions = () => {
  const scrollToAnchor = (anchorId) => {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const navigate = useNavigate();
  return (
    <div className="banner-content">
      <div className="banner-btn">
        <div
          className="gradient-btn wow fadeInLeft"
          onClick={() => navigate('/login')}
        >
          Sign Up Now
        </div>
        <div
          onClick={() => scrollToAnchor('subscribe')}
          className="gradient-btn gradient-btn-two wow fadeInRight"
        >
          Start Your Free Trial
        </div>
        {/* <Link
        to="/login"
        
        data-wow-delay=".6s"
      >
      </Link> */}
        {/* <Link to="/work" data-wow-delay=".6s"></Link> */}
      </div>
    </div>
  );
};

export default CalltoActions;
