import React from 'react';
import * as Styled from '../styled';

const Icon = ({ unreadMessages, open, openClick }) => {
  return (
    <Styled.IconWrapper onClick={openClick} open={open}>
      {unreadMessages && unreadMessages.length ? (
        <Styled.Count>
          <p>{unreadMessages.length}</p>
        </Styled.Count>
      ) : (
        ''
      )}
      <Styled.Icon
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none"
      >
        <path
          d="M3.64871 0.019043H22.3513C23.2188 0.019043 24.0057 0.372786 24.576 0.943077C25.1463 1.51337 25.5 2.30021 25.5 3.16775V15.8379C25.5 16.7054 25.1463 17.4923 24.576 18.0626C24.0057 18.6329 23.2175 18.9866 22.3513 18.9866H12.0713L5.2856 23.8206C4.89689 24.0977 4.35753 24.0062 4.0818 23.6175C3.97286 23.4655 3.92175 23.2907 3.92175 23.1172H3.91906V18.9866H3.64871C2.78116 18.9866 1.99432 18.6329 1.42403 18.0626C0.853741 17.4923 0.5 16.7041 0.5 15.8379V3.16775C0.5 2.30021 0.853741 1.51337 1.42403 0.943077C1.99432 0.372786 2.78116 0.019043 3.64871 0.019043ZM22.3499 1.75144H3.64871C3.25999 1.75144 2.90625 1.9115 2.64801 2.1684C2.38976 2.4253 2.23105 2.78038 2.23105 3.1691V15.8392C2.23105 16.228 2.39111 16.583 2.64801 16.8399C2.90491 17.0968 3.25999 17.2569 3.64871 17.2569H4.78525C5.26274 17.2569 5.65145 17.6443 5.65145 18.1231V21.4426L11.2454 17.4573C11.3947 17.3322 11.5884 17.2569 11.7996 17.2569H22.3513C22.74 17.2569 23.0937 17.0968 23.352 16.8399C23.6102 16.583 23.769 16.228 23.769 15.8392V3.1691C23.769 2.78038 23.6089 2.42664 23.352 2.1684C23.0951 1.91015 22.7387 1.75144 22.3499 1.75144Z"
          fill="#1C102F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.15561 10.8021C7.99088 10.8021 8.67011 10.1215 8.67011 9.28757C8.67011 8.45365 7.98953 7.77307 7.15561 7.77307C6.3217 7.77307 5.64111 8.45365 5.64111 9.28757C5.64111 10.1215 6.32035 10.8021 7.15561 10.8021Z"
          fill="#1C102F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9999 10.8021C13.8351 10.8021 14.5143 10.1215 14.5143 9.28757C14.5143 8.45365 13.8338 7.77307 12.9999 7.77307C12.1646 7.77307 11.4854 8.45365 11.4854 9.28757C11.4854 10.1215 12.1646 10.8021 12.9999 10.8021Z"
          fill="#1C102F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8436 10.8021C19.6789 10.8021 20.3581 10.1215 20.3581 9.28757C20.3581 8.45365 19.6775 7.77307 18.8436 7.77307C18.0097 7.77307 17.3291 8.45365 17.3291 9.28757C17.3291 10.1215 18.0083 10.8021 18.8436 10.8021Z"
          fill="#1C102F"
        />
      </Styled.Icon>
    </Styled.IconWrapper>
  );
};

export default Icon;
