const About2 = () => {
  return (
    <section className="about-area-two">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="about-content-wrap">
              <div
                className="section-title-three mb-50 title-animation wow fadeInUp"
                data-wow-delay=".2s"
              >
                <h2 className="title">
                  Mastering Content and Voiceover Creation{" "}
                  <span>in 3 Simple Steps</span>
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="about-content-two">
                    <p>
                      English, Arabic, German, Turkish, French, Italian,
                      Russian, Chinese, Japanese, Korean... Convert your texts
                      to speech naturally, fluently and realistically with more
                      than 550 voices in 75+ languages with 140 dialects.
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <i className="far fa-hand-point-right" />
                        Insert text,
                      </li>
                      <li>
                        <i className="far fa-hand-point-right" />
                        Choose a voice that you like,
                      </li>
                      <li>
                        <i className="far fa-hand-point-right" />
                        Click on Generate and Download your voiceover!
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-img-two">
                    <img src="/assets/img/images/h3_about_img.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About2;
