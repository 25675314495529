import { useEffect, useState } from 'react';
import PredictServiceInstance from '../services/predict.service';

const usePredict = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [predict, setPredict] = useState('');

  useEffect(() => {
    const predict$ = PredictServiceInstance.state$.subscribe((state) => {
      setError(state.error);
      setLoading(state.loading);
      setPredict(state.predict);
    });

    return () => {
      predict$.unsubscribe();
    };
  }, []);

  return {
    loading,
    error,
    predict,
  };
};

export default usePredict;
